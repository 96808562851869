<script>
  import { onMount } from 'svelte';
  import { useNavigate } from 'svelte-navigator';
  import { format } from 'svelte-i18n';

  import { Logo } from 'Client/components/logo';
  import { Page } from 'Client/components/page';

  import { Choose } from 'Client/pages/home/choose';
  import { Code } from 'Client/pages/home/code';
  import { Password } from 'Client/pages/home/password';
  import { Username } from 'Client/pages/home/username';

  import { PasswordService } from 'Client/services/password';
  import { PolicyService } from 'Client/services/policy';

  import { queryParams } from 'Common/utils/query-params';
  import { LocalStorage } from 'Common/utils/local-storage';

  const navigate = useNavigate();

  let email = '';
  let isValidEmail = false;
  let loading = false;
  let password = '';
  let policy = {};
  let providers = [];
  let requires2FA = false;

  let loginErrorMessage = '';
  let policyErrorMessage = '';

  let show2FA = false;
  let showPassword = false;
  let showUsername = true;

  const reset = () => {
    // clear out any error messagesa and values
    email = password = loginErrorMessage = '';
    // restore to initial state
    showPassword = show2FA = isValidEmail = loading = false;
    // clear query params
    queryParams.delete('email', 'step');
    // reset previous providers
    providers = [];
  };

  const next = async () => {
    loading = true;

    policy = await PolicyService.get(email);
    requires2FA = policy?.auth?.providers?.password?.tfa;
    loading = false;

    if (policy.code === 422) {
      policyErrorMessage = $format('label.ERROR_FREE_DOMAINS_NOT_ALLOWED');
      return;
    }

    if (!Object.keys(policy).length || policy.code === 404) {
      policyErrorMessage = $format('label.ERROR_USER_NO_POLICY');
      return;
    }

    const providerConfigs = policy?.auth?.providers || {};
    const supported = Object.keys(providerConfigs);

    if (supported.length === 1) {
      const [provider] = supported;
      const config = providerConfigs[provider] || {};

      if (provider !== 'password' && provider !== 'saml') {
        policyErrorMessage = $format('label.ERROR_REQUIRES_PROVIDER', {
          values: {
            provider: config.name
          }
        });
      } else {
        if (provider === 'password') {
          showPassword = true;
        }

        if (provider === 'saml') {
          window.location.href = config.redirectUrl;
        }
      }
    } else {
      providers = supported.map((provider) => {
        const config = providerConfigs[provider];
        config.type = provider;

        return config;
      });
    }
  };

  const login = async () => {
    if (email?.length && password?.length) {
      loading = true;

      const storedAccounts = LocalStorage.get('accounts');
      const response = await PasswordService.verify(email, password);

      if (response === undefined || response?.message === 'AUTH_FAILURE') {
        loginErrorMessage = $format('label.ERROR_CREDENTIALS_DO_NOT_MATCH');
        loading = false;
      } else if (response?.message === 'TFA_REQURIED') {
        show2FA = true;
        loading = false;
      } else {
        if (response?.portalurl) {
          LocalStorage.update(
            'accounts',
            Object.assign(
              { ...storedAccounts },
              {
                [email]: {
                  email,
                  lastUsed: new Date().getTime()
                }
              }
            )
          );

          window.location.replace(response.portalurl);
        }
      }
    }
  };

  const redirectWithEmail = (path) => {
    if (email) {
      path += `?email=${email}`;
    }

    navigate(path);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    loginErrorMessage = '';
    policyErrorMessage = '';

    if (showPassword) {
      login();
    } else {
      if (isValidEmail) {
        next();
      }
    }
  };

  const parseQueryString = () => {
    const emailQueryParam = queryParams.get('email');
    const stepQueryParam = queryParams.get('step');

    if (emailQueryParam && stepQueryParam === 'password') {
      email = emailQueryParam;
      showPassword = true;
      showUsername = false;
      show2FA = false;
    } else if (emailQueryParam && stepQueryParam === 'choose') {
      email = emailQueryParam;
      showPassword = false;
      showUsername = false;
      show2FA = false;
      next();
    } else {
      showPassword = false;
      showUsername = true;
      show2FA = false;
    }
  };

  window.addEventListener('popstate', parseQueryString);

  onMount(parseQueryString);

  $: showUsername = !showPassword && !providers.length;
</script>

<Page id="auth" className={loginErrorMessage ? 'shake' : ''}>
  <Logo />

  <form on:submit={onSubmit}>
    {#if showUsername}
      <Username {showPassword} {loading} {policyErrorMessage} {redirectWithEmail} bind:email bind:isValidEmail {onSubmit} />
    {:else if showPassword}
      {#if !show2FA}
        <Password {requires2FA} {showPassword} {reset} {email} {loading} {redirectWithEmail} {loginErrorMessage} bind:password on:submitform={onSubmit} />
      {:else}
        <Code {email} {password} bind:show2FA />
      {/if}
    {:else}
      <Choose {providers} {reset} {email} bind:showPassword />
    {/if}
  </form>
</Page>

<style lang="scss" src="./home.scss"></style>
