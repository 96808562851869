import { debounce } from 'Common/utils/debounce';

const onWindowResize = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

export const setupViewport = () => {
  window.addEventListener('resize', debounce(onWindowResize, 200));
  setTimeout(onWindowResize(), 500);
}
