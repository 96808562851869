<script>
  export let onRemove = () => {}
</script>

<div class="pill" {...$$props}>
  <div>
    <slot />
  </div>
  <i class="os-icon-x-circle" on:click={onRemove} />
</div>

<style lang="scss" src="./pill.scss"></style>
