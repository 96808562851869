import { APITransport } from 'Client/transports/api';

export const PolicyService = {
  get(username) {
    return APITransport.get({
//      baseURL: 'http://localhost:3000',
      path: `/auth/policy/${username}`
    });
  }
};
