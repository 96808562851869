import { default as App } from './app/app.svelte';

import 'Client/translations/i18n';

import './styles/app.scss';

const app = new App({
  target: document.getElementById('app')
});

export default app;
