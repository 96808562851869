<script>
  import { onMount } from 'svelte';
  import { format, locales, locale } from 'svelte-i18n';
  import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'sveltestrap';

  import { localeLabelsEnum } from 'Client/enums/locale';
  import { queryParams } from 'Common/utils/query-params';

  let selectedLocale = '';
  let localeFeatureFlag = false;

  onMount(() => {
    localeFeatureFlag = queryParams.get('features')?.includes('locale');

    locale.subscribe((lang) => {
      selectedLocale = lang;
    });
  });
</script>

<header>
  <div class="container-fluid">
    <div class="flex flex-align--center justify-content--end">
      {#if localeFeatureFlag}
        <div class="nav">
          <Dropdown>
            <DropdownToggle nav>
              <i class="os-icon-globe" />
              <span>{localeLabelsEnum[selectedLocale ?? 'en']}</span>
            </DropdownToggle>
            <DropdownMenu end>
              {#each $locales as lang}
                <DropdownItem class={selectedLocale === lang ? 'active' : ''} on:click={() => locale.set(lang)}>
                  {localeLabelsEnum[lang]}
                </DropdownItem>
              {/each}
            </DropdownMenu>
          </Dropdown>
        </div>
      {/if}
      <div class="nav">
        <Dropdown>
          <DropdownToggle nav>
            <i class="os-icon-life-buoy" />
            <span>{$format('label.SUPPORT')}</span>
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem href="https://help.opensense.com/" target="_blank">
              {$format('label.KNOWLEDGE_BASE')}
            </DropdownItem>
            <DropdownItem href="https://www.opensense.com/support" target="_blank">
              {$format('label.SUPPORT_TICKET')}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem href="mailto:help@opensense.com" target="_blank">
              {$format('label.CONTACT_SUPPORT')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  </div>
</header>

<style lang="scss" src="./header.scss"></style>
