<script>
  import zxcvbn from 'zxcvbn';
  import { format } from 'svelte-i18n';

  export let password = '';
  export let inputs = [];
  export let score = 0;
  export let result = null;
  export let showFeedback = false;
  export let showScoreLabel = true;

  export let messages = {
    0: $format('label.PASSWORD_STRENGTH_0'),
    1: $format('label.PASSWORD_STRENGTH_1'),
    2: $format('label.PASSWORD_STRENGTH_2'),
    3: $format('label.PASSWORD_STRENGTH_3'),
    4: $format('label.PASSWORD_STRENGTH_4')
  };

  $: {
    result = zxcvbn(password, inputs);
    score = result.score;
  }
</script>

<div class="password-strength" aria-label={$format('label.PASSWORD_STRENGTH')}>
  <ul>
    <li class:active={password.length > 0 && score >= 0 }></li>
    <li class:active={score > 0}></li>
    <li class:active={score > 1}></li>
    <li class:active={score > 2}></li>
    <li class:active={score > 3}></li>
  </ul>
  <div>
    {#if showFeedback && result.feedback.warning}
      <small>{result.feedback.warning}</small>
    {/if}

    {#if showScoreLabel && password.length}
      <small class="score-{result.score}">{messages[result.score]}</small>
    {/if}
  </div>
</div>

<style lang="scss" src="./strength.scss"></style>
