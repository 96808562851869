<script>
  import { onMount } from 'svelte';
  import { format } from 'svelte-i18n';

  import { Button } from 'Client/components/button';
  import { Input } from 'Client/components/input';
  import { Loader } from 'Client/components/loader';
  import { Logo } from 'Client/components/logo';
  import { Page } from 'Client/components/page';
  import { Panel } from 'Client/components/panel';

  import { Instructions } from './instructions';

  import { PasswordService } from 'Client/services/password';

  import { queryParams } from 'Common/utils/query-params';

  let email = '';
  let emailRef = null;
  let loading = false;
  let isValidEmail = false;
  let showEmailInstructions = false;

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isValidEmail) {
      loading = true;

      await PasswordService.reset(email);

      showEmailInstructions = true;

      loading = false;
    }
  };

  const parseQueryString = () => {
    const emailQueryParam = queryParams.get('email');

    if (emailQueryParam) {
      email = emailQueryParam;
    }

    setTimeout(() => emailRef && emailRef.focus(), 0);
  };

  window.addEventListener('popstate', parseQueryString);

  onMount(parseQueryString);
</script>

<Page id="forgot">
  <Logo />

  <Panel>
    <form on:submit={onSubmit}>
      <Loader show={loading} backdrop={true} />

      {#if showEmailInstructions}
        <Instructions />
      {:else}
        <h1>{$format('label.FORGOT_PASSWORD')}</h1>

        <p>{$format('label.MESSAGE_ENTER_WORK_EMAIL_TO_SIGN_IN')}</p>

        <div class="fields">
          <Input
            id="email"
            name="email"
            type="email"
            autocomplete="email"
            bind:ref={emailRef}
            bind:value={email}
            placeholder={$format('label.EMAIL_PLACEHOLDER')}
            bind:valid={isValidEmail}
            aria-label={$format('label.EMAIL_ADDRESS')}
          />
        </div>

        <div class="actions">
          <Button kind="link" on:click={() => history.back()}>
            {$format('label.BACK')}
          </Button>
          <Button kind="primary" type="submit" disabled={!isValidEmail}>
            {$format('label.RESET')} <span class="hide-xs">&nbsp;{$format('label.PASSWORD')}</span>
          </Button>
        </div>
      {/if}
    </form>
  </Panel>
</Page>

<style lang="scss" src="./forgot.scss"></style>
