<script>
  import { onMount } from 'svelte';
  import { useNavigate } from 'svelte-navigator';
  import { format } from 'svelte-i18n';

  import { Button } from 'Client/components/button';
  import { Input } from 'Client/components/input';
  import { Loader } from 'Client/components/loader';
  import { Logo } from 'Client/components/logo';
  import { Page } from 'Client/components/page';
  import { Panel } from 'Client/components/panel';

  import { AccountService } from 'Client/services/account';

  import { queryParams } from 'Common/utils/query-params';
  import { emailRegExp } from 'Common/utils/regexp';

  const navigate = useNavigate();

  let email = '';
  let emailRef = null;
  let loading = false;
  let isValidEmail = false;
  let verifyEmail = false;
  let registrationErrorMessage = '';

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isValidEmail) {
      loading = true;

      const response = await AccountService.create(email);

      if (response === undefined || response?.code === 500) {
        registrationErrorMessage = $format('label.ERROR_REGISTRATION_FAILED');
      } else if (response?.code === 422) {
        registrationErrorMessage = $format('label.ERROR_FREE_DOMAINS_NOT_ALLOWED');
      } else if (response?.code === 404) {
        if (response?.message === 'User already registered') {
          registrationErrorMessage = $format('label.ERROR_USER_EXISTS');
        } else {
          registrationErrorMessage = $format('label.ERROR_USER_NOT_ALLOWED');
        }
      } else {
        verifyEmail = true;
      }

      loading = false;
    }
  };

  const onKeyUp = () => {
    registrationErrorMessage = '';
  };

  onMount(() => {
    const emailQueryParam = queryParams.get('email');

    if (emailQueryParam) {
      email = emailQueryParam;
    }

    setTimeout(() => emailRef.focus(), 0);
  });
</script>

<Page id="register">
  <Logo />

  {#if !verifyEmail}
    <Panel>
      <form on:submit={onSubmit}>
        <Loader show={loading} backdrop={true} />

        <h1>{$format('label.NEW_ACCOUNT')}</h1>

        <p>{$format('label.MESSAGE_ENTER_WORK_EMAIL_TO_REGISTER')}</p>

        <div class="fields">
          <Input
            type="email"
            id="email"
            name="email"
            autocomplete="email"
            bind:ref={emailRef}
            bind:value={email}
            on:keyup={onKeyUp}
            placeholder={$format('label.EMAIL_PLACEHOLDER')}
            bind:valid={isValidEmail}
            aria-label={$format('label.EMAIL_ADDRESS')}
            validation={emailRegExp}
            autofocus
          />
        </div>

        {#if registrationErrorMessage}
          <div class="error">{registrationErrorMessage}</div>
        {/if}

        <div class="actions">
          <Button kind="link" on:click={() => navigate('/')}>
            {$format('label.BACK')}
          </Button>
          <Button kind="primary" type="submit" disabled={!isValidEmail}>
            {$format('label.CREATE_ACCOUNT')}
          </Button>
        </div>
      </form>
    </Panel>
  {:else}
    <Panel>
      <img src="https://opensense.s3.amazonaws.com/send.svg" alt={$format('label.OPENSENSE_LOGO')} />
      <h1>{$format('label.SUCCESS')}</h1>
      <p>{$format('label.MESSAGE_PLEASE_CHECK_EMAIL')}</p>
    </Panel>
  {/if}
</Page>

<style lang="scss" src="./register.scss"></style>
