<script>
  import { onMount } from 'svelte';
  import { format } from 'svelte-i18n';

  import { Button } from 'Client/components/button';
  import { Loader } from 'Client/components/loader';
  import { Panel } from 'Client/components/panel';
  import { Pill } from 'Client/components/pill';
  import { PasswordPreview } from 'Client/components/password/preview';

  import { queryParams } from 'Common/utils/query-params';

  export let showPassword = null;
  export let loading = null;
  export let reset = null;
  export let email = null;
  export let password = '';
  export let redirectWithEmail = null;
  export let requires2FA = false;
  export let loginErrorMessage = '';

  let passwordRef = null;

  const onKeyUp = () => {
    loginErrorMessage = '';
  };

  onMount(() => {
    queryParams.set({
      step: 'password',
      email
    });

    setTimeout(() => passwordRef && passwordRef.focus(), 0);
  });
</script>

<Panel className={showPassword ? 'subtleFadeInUp' : 'subtleFadeOutDown'}>
  <Loader show={loading} backdrop={true} />

  <div class="enter-password">
    <div class="email">
      <Pill onRemove={reset}>{email}</Pill>
    </div>

    <PasswordPreview
      id="current-password"
      name="current-password"
      autocomplete="current-password"
      bind:ref={passwordRef}
      bind:value={password}
      on:keyup={onKeyUp}
      placeholder={$format('label.PASSWORD_PLACEHOLDER')}
      aria-label={$format('label.PASSWORD_PLACEHOLDER')} />

    {#if loginErrorMessage}
      <div class="error">{loginErrorMessage}</div>
    {/if}

    <div class="actions">
      <Button kind="link" on:click={() => redirectWithEmail('/forgot')}>
        {$format('label.FORGOT_PASSWORD')}
      </Button>
      <Button kind="primary" disabled={!password.length} type="submit">
        {$format(requires2FA ? 'label.NEXT' : 'label.LOG_IN')}
      </Button>
    </div>
  </div>
</Panel>

<style lang="scss" src="./password.scss"></style>
