<script>
  export let backdrop = false;
  export let show = false;
  export let inline = false;
  export let size = 'default'
</script>

{#if show}
  {#if backdrop}
    <div class="loader-backdrop"></div>
  {/if}
  <div class="loader {size}" class:inline>
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
  </div>
{/if}

<style lang="scss" src="./loader.scss"></style>
