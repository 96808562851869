<script>
  import { onMount } from 'svelte';
  import { format } from 'svelte-i18n';

  import { AccountPicker } from 'Client/components/accounts';
  import { Button } from 'Client/components/button';
  import { Input } from 'Client/components/input';
  import { Loader } from 'Client/components/loader';
  import { Panel } from 'Client/components/panel';
  import { Provider } from 'Client/components/provider';

  import { LocalStorage } from 'Common/utils/local-storage';
  import { emailRegExp } from 'Common/utils/regexp';

  export let showPassword = null;
  export let loading = null;
  export let email = '';
  export let redirectWithEmail = null;
  export let policyErrorMessage = '';
  export let isValidEmail = false;
  export let onSubmit;

  let emailRef = null;
  let selectedAccount = {};
  let accountsCache = [];
  let useDifferentAccount = false;

  const storedAccounts = LocalStorage.get('accounts');

  const onKeyUp = () => {
    policyErrorMessage = '';
  };

  const showEmailField = () => {
    useDifferentAccount = true;
    email = '';
  };

  onMount(() => {
    if (storedAccounts) {
      accountsCache = Object.keys(storedAccounts).map((key) => storedAccounts[key]);

      if (accountsCache.length > 1) {
        accountsCache = accountsCache.sort((a, b) => (a.lastUsed < b.lastUsed ? 1 : -1));
      }
    }

    selectedAccount = accountsCache.find((account) => account.email === email) || accountsCache[0];

    if (selectedAccount) {
      email = selectedAccount.email;
      isValidEmail = true;
    } else {
      useDifferentAccount = true;
    }

    setTimeout(() => emailRef && emailRef.focus(), 0);
  });

  $: useDifferentAccount && setTimeout(() => emailRef && emailRef.focus(), 100);
</script>

<Panel className={showPassword !== null ? 'subtleFadeInUp' : showPassword ? 'subtleFadeOutDown' : ''}>
  <Loader show={loading} backdrop={true} />

  <div class="enter-username">
    <p class="sign-in">
      {$format('label.MESSAGE_SIGN_IN_WITH')}
    </p>

    <div class="sso-options">
      <Provider url="https://login.sendergen.com/auth/azureOAuth?v2=true" icon="https://opensense.s3.amazonaws.com/microsoft.png" label="Office 365" />
      <Provider url="https://login.sendergen.com/auth/google?v2=true" icon="https://opensense.s3.amazonaws.com/google.png" label="Google" />
      <Provider url="https://login.sendergen.com/auth/forcedotcom?v2=true" icon="https://opensense.s3.amazonaws.com/salesforce.png" label="Salesforce" />
    </div>

    <hr />

    <p class="work-email">
      {$format('label.MESSAGE_SIGN_IN_WITH_WORK_EMAIL')}
    </p>

    <div class="fields">
      {#if selectedAccount && accountsCache.length}
        <AccountPicker bind:accountsCache bind:isValidEmail bind:selectedAccount bind:email bind:useDifferentAccount onClick={onSubmit} />

        {#if !useDifferentAccount}
          <Button kind="outline" on:click={showEmailField}>{$format('label.USE_ANOTHER_ACCOUNT')}</Button>
        {/if}
      {/if}

      {#if useDifferentAccount}
        <div class="margin-large--top">
          <Input
            id="email"
            name="email"
            type="email"
            bind:ref={emailRef}
            bind:value={email}
            on:keyup={onKeyUp}
            placeholder={$format('label.EMAIL_PLACEHOLDER')}
            bind:valid={isValidEmail}
            aria-label={$format('label.EMAIL_ADDRESS')}
            validation={emailRegExp}
            autocomplete="email"
          />
        </div>
      {/if}

      {#if policyErrorMessage}
        <div class="error">{policyErrorMessage}</div>
      {/if}
      <div class="actions">
        <Button kind="primary" type="submit" disabled={!isValidEmail}>
          {$format('label.NEXT')}
        </Button>
        <Button kind="link" on:click={() => redirectWithEmail('/register')}>
          {$format('label.CREATE_ACCOUNT')}
        </Button>
      </div>
    </div>
  </div>
</Panel>

<style lang="scss" src="./username.scss"></style>
