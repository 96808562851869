<script>
  import { format } from 'svelte-i18n';

  import { Page } from 'Client/components/page';
  import { Panel } from 'Client/components/panel';

  const url = new URL(location.href);
  const params = new URLSearchParams(url.search);
  const msg = params.get('msg');
  const message = $format(`label.${msg}`, { default: $format('label.ERROR_ACTION_NOT_ALLOWED') });
</script>

<Page id="not-allowed">
  <Panel>
    <h1>{$format('label.NOT_ALLOWED')}</h1>
    <p>{@html message}</p>
    <br />
    <p>{@html $format('label.RETURN_TO_SIGN_IN')}</p>
  </Panel>
</Page>

<style lang="scss" src="./not-allowed.scss"></style>
