export const base = {
  color: {
    actions: '#d52b1e',
    black: '#21333d',
    body: '#12171C',
    text: '#21333d',
    mint: '#1fd1b6',
    white: '#fff',
    navy: '#006cb7',
    indigo: '#1f3d4e',
    gray: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eceff1',
      300: '#e6e9eb',
      400: '#d5dae2',
      500: '#b9bdc5',
      600: '#828c93',
      700: '#5b636a',
      800: '#464e56',
      900: '#232a32'
    },
    green: {
      100: '#b0ebb7',
      200: '#82e18b',
      300: '#4ad658',
      400: '#00c914',
      500: '#2ec23d'
    },
    yellow: {
      100: '#fff8ed',
      200: '#ffe9bf',
      300: '#ffd37f',
      400: '#ffbc3d'
    },
    blue: {
      100: '#99cfff',
      200: '#80c3ff',
      300: '#4dabff',
      400: '#0087ff',
      500: '#0066eb'
    },
    orange: {
      100: '#f2d294',
      200: '#464e56',
      300: '#00ac3e',
      400: '#ffb800',
      500: '#ffb220'
    },
    red: {
      100: '#ffc0b7',
      200: '#ff9a8c',
      300: '#ff6666',
      400: '#ff3c3c',
      500: '#d52b1e'
    }
  },
  link: {
    color: 'var(--color-navy)',
    decoration: 'none',
    active: {
      color: 'var(--color-indigo)',
      decoration: 'none'
    },
    hover: {
      color: 'var(--color-blue-400)',
      decoration: 'underline'
    }
  },
  brand: {
    primary: 'var(--color-blue-400)',
    success: 'var(--color-green-400)',
    info: 'var(--color-yellow-400)',
    warning: 'var(--color-orange-400)',
    danger: 'var(--color-red-400)',
    capri: '#1bbbff',
    carbon: '#21333d',
    opal: '#ec6454',
    sandy: '#f3a86d',
    green: '#5ec1b2'
  },
  font: {
    family: {
      base: 'var(--font-family-sansserif)',
      monospace: "'ui-monospace', 'SFMono-Regular', Menlo, Monaco, Consolas, 'Courier New', monospace",
      sansSerif: "'Rubik', sans-serif",
      serif: "'ui-serif', Georgia, 'Times New Roman', Times, serif"
    },
    size: {
      jumbo: '32px',
      xxxlarge: '24px',
      xxlarge: '22px',
      xlarge: '20px',
      large: '18px',
      base: '16px',
      medium: '14px',
      small: '12px',
      xsmall: '10px',
      xxsmall: '8px',
      xxxsmall: '4px'
    },
    weight: {
      thin: 100,
      light: 300,
      base: 400,
      medium: 500,
      bold: 700,
      black: 900
    }
  },
  line: {
    height: {
      small: 1.16,
      base: 1.3,
      large: 1.4,
      computed: 'calc(var(--font-size-base) * var(--line-height-base))'
    }
  },
  heading: {
    color: 'inherit',
    weight: 'var(--font-weight-base)',
    line: {
      height: 1.2
    },
    font: {
      family: 'inherit',
      size: {
        title: 'var(--font-size-jumbo)',
        h1: 'var(--font-size-xxxlarge)',
        h2: 'var(--font-size-xxlarge)',
        h3: 'var(--font-size-xlarge)',
        h4: 'var(--font-size-large)',
        h5: 'var(--font-size-base)',
        h6: 'var(--font-size-medium)',
        section: 'var(--font-size-medium)'
      },
      weight: {
        title: 'var(--font-weight-medium)',
        h1: 'var(--font-weight-medium)',
        h2: 'var(--font-weight-medium)',
        h3: 'var(--font-weight-medium)',
        h4: 'var(--font-weight-medium)',
        h5: 'var(--font-weight-medium)',
        h6: 'var(--font-weight-medium)'
      }
    }
  },
  border: {
    color: 'var(--color-gray-400)',
    radius: {
      small: '2px',
      base: '4px',
      large: '8px',
      xlarge: '12px',
      xxlarge: '16px',
      xxxlarge: '20px'
    }
  },
  margin: {
    xsmall: '2px',
    small: '4px',
    base: '8px',
    large: '16px',
    xlarge: '24px',
    xxlarge: '32px',
    xxxlarge: '40px',
    jumbo: '48px'
  },
  padding: {
    xsmall: '2px',
    small: '4px',
    base: '8px',
    large: '16px',
    xlarge: '24px',
    xxlarge: '32px',
    xxxlarge: '40px',
    jumbo: '48px'
  },
  box: {
    shadow: {
      small: '0 1px 1px rgba(0,0,0, .1)',
      base: '0 1px 3px rgba(0,0,0, .1)',
      large: '0 1px 5px rgba(0,0,0, .1)'
    }
  },

  header: {
    color: 'var(--color-gray-500)',
    font: {
      size: 'var(--font-size-medium)'
    },
    padding: 'var(--padding-xlarge) 0',
    zindex: 11,

    dropdown: {
      trigger: {
        background: {
          color: {
            hover: 'rgba(225, 225, 225, .25)'
          }
        },
        border: {
          radius: 'var(--border-radius-large)'
        },
        color: 'var(--color-gray-500)',

        icon: {
          margin: {
            _: '1px   var(--margin-small) 0 0',
            screen: {
              md: '0 var(--margin-base) 0 0'
            }
          }
        }
      }
    }
  },

  footer: {
    border: {
      top: '1px solid rgba(200,220,225, .3)'
    },
    color: 'var(--color-gray-100)',
    font: {
      size: 'var(--font-size-medium)'
    },
    padding: 'var(--padding-large) 0',
    zindex: 10,

    links: {
      background: {
        color: {
          hover: 'rgba(255, 255, 255, .05)'
        }
      },
      border: {
        radius: 'var(--border-radius-large)'
      },
      color: 'var(--color-gray-500)',
      padding: 'var(--padding-base) var(--padding-large)',
      transition: 'all .2s linear'
    },

    copyright: {
      color: {
        _: 'rgba(255, 255, 255, .5)',
        screen: {
          md: 'var(--color-gray-500)'
        }
      }
    }
  },

  input: {
    background: {
      _: 'var(--color-white)',
      disabled: 'var(--color-gray-100)'
    },
    border: {
      _: '1px solid var(--color-gray-400)',
      disabled: '1px solid var(--color-gray-300)',
      focus: '1px solid var(--color-blue-400)',
      error: '1px solid var(--color-red-400)',
      success: '1px solid var(--color-green-400)',

      radius: 'var(--border-radius-base)'
    },

    box: {
      shadow: {
        _: 'none',
        focus: '0 0 0 2px var(--color-blue-400)'
      }
    },

    color: {
      _: 'var(--color-text)',
      disabled: 'var(--color-gray-400)'
    },

    font: {
      size: 'var(--font-size-base)',
      weight: 'var(--font-weight-base)'
    },
    height: '42px',
    padding: 'var(--padding-base) var(--padding-large)',
    placeholder: {
      color: {
        _: 'var(--color-gray-500)',
        disabled: 'var(--color-gray-400)'
      }
    }
  },

  button: {
    background: {
      disabled: 'var(--color-gray-200)'
    },
    border: {
      _: '1px solid transparent',
      disabled: 'var(--color-gray-200)',
      radius: 'var(--border-radius-base)'
    },
    color: {
      disabled: 'var(--color-gray-500)'
    },
    font: {
      size: 'var(--font-size-base)',
      weight: 'var(--font-weight-base)'
    },
    margin: 0,
    padding: 'calc(var(--padding-base) + 2px) var(--padding-large)',
    height: '42px',
    min: {
      width: '50px'
    },

    primary: {
      background: {
        _: 'var(--color-blue-400)',
        active: '#0360b4',
        hover: '#017eed'
      },
      border: {
        _: 'var(--color-blue-400)',
        active: '#0360b4',
        hover: '#017eed'
      },
      color: {
        _: 'var(--color-white)',
        active: 'var(--button-primary-color)',
        hover: 'var(--button-primary-color)'
      }
    },

    secondary: {
      background: {
        _: 'var(--color-gray-100)',
        active: '#e2e1e3',
        hover: '#e2e1e3'
      },
      border: {
        _: 'var(--color-gray-100)',
        active: '#e2e1e3',
        hover: '#c9ccd3'
      },
      color: {
        _: 'var(--color-gray-800)',
        active: 'var(--button-secondary-color)',
        hover: 'var(--button-secondary-color)'
      }
    },
    outline: {
      background: {
        _: 'transparent',
        active: '#e2e1e3',
        hover: 'transparent'
      },
      border: {
        _: 'var(--color-gray-900)',
        active: 'var(--color-gray-800)',
        hover: 'var(--color-gray-600)'
      },
      color: {
        _: 'var(--color-gray-900)',
        active: 'var(--color-gray-800)',
        hover: 'var(--color-gray-600)'
      }
    },

    link: {
      background: {
        _: 'transparent',
        active: 'var(--color-gray-300)',
        hover: 'var(--color-gray-100)'
      },
      border: {
        _: 'transparent',
        active: 'var(--color-gray-300)',
        hover: 'var(--color-gray-100)'
      },
      color: {
        _: 'var(--link-color)',
        active: 'var(--link-active-color)',
        hover: 'var(--link-color)'
      }
    },

    success: {
      background: {
        _: 'var(--color-green-400)',
        active: '#04a83f',
        hover: '#06bc48'
      },
      border: {
        _: 'var(--color-green-400)',
        active: '#04a83f',
        hover: '#06bc48'
      },
      color: {
        _: 'var(--color-white)',
        active: 'var(--button-success-color)',
        hover: 'var(--button-success-color)'
      }
    },

    text: {
      background: {
        _: 'transparent !important',
        active: 'var(--color-gray-400)',
        hover: 'var(--color-gray-300)'
      },
      border: {
        _: 'transparent !important',
        active: 'var(--color-gray-400)',
        hover: 'var(--color-gray-300)'
      },
      color: {
        _: 'var(--color-text)',
        active: 'var(--button-text-color)',
        hover: 'var(--button-text-color)'
      }
    }
  },

  dropdown: {
    menu: {
      background: {
        color: 'var(--color-white)'
      },
      color: 'var(--color-text)',
      border: {
        _: '0',
        radius: 'var(--border-radius-large)'
      },
      box: {
        shadow: '0 0 20px rgba(0,0,0, .1)'
      },
      font: {
        size: 'var(--font-size-base)'
      },
      padding: 'var(--padding-small) 0',
      zindex: 1000,

      item: {
        background: {
          color: {
            active: 'var(--color-white)',
            hover: 'var(--color-gray-100)'
          }
        },
        border: {
          radius: 'var(--border-radius-base)'
        },
        color: {
          active: 'var(--color-text)'
        },
        font: {
          size: 'var(--font-size-medium)',
          weight: {
            active: 'var(--font-weight-medium)'
          }
        },
        padding: 'var(--padding-large) var(--padding-xlarge)',

        divider: {
          border: '1px solid #f1f1f1',
          margin: 'var(--margin-small) 0'
        }
      }
    }
  },

  panel: {
    background: {
      color: 'var(--color-white)'
    },
    border: {
      radius: 'var(--border-radius-large)'
    },
    box: {
      shadow: '0 0 20px 0 rgba(0, 0, 0, .1)'
    },
    margin: 'auto',
    padding: {
      _: 'var(--padding-xxxlarge) var(--padding-xxxlarge)',
      screen: {
        sm: 'var(--padding-xxlarge) var(--padding-large)'
      }
    }
  },

  pill: {
    background: {
      color: 'var(--color-gray-200)'
    },
    border: {
      radius: '20px'
    },
    color: 'var(--color-gray-800)',
    font: {
      size: 'var(--font-size-medium)'
    },
    padding: 'var(--padding-base) var(--padding-base) var(--padding-base) calc(var(--padding-base) + 4px)',

    icon: {
      color: {
        _: 'var(--color-gray-700)',
        hover: 'var(--color-gray-900)'
      },
      font: {
        size: 'var(--font-size-large)'
      },
      margin: '0 0 0 var(--margin-small)'
    }
  },

  password: {
    policy: {
      rules: {
        margin: 'var(--margin-base) 0 0 0'
      },
      rule: {
        line: {
          height: 'var(--font-size-medium)'
        },
        margin: 'var(--margin-small)',

        icon: {
          color: {
            _: 'var(--color-gray-400)',
            active: 'var(--brand-green)'
          },
          font: {
            size: 'var(--font-size-medium)',
            weight: 'var(--font-weight-medium)'
          },
          margin: '0 var(--margin-base) 0 0'
        }
      }
    },
    preview: {
      icon: {
        color: {
          _: 'var(--color-gray-500)',
          hover: 'var(--color-gray-900)'
        }
      }
    },
    strength: {
      margin: 'var(--margin-large) 0 var(--margin-base)',
      label: {
        color: {
          _: 'var(--color-gray-800)',
          score: {
            0: 'var(--color-gray-800)',
            1: 'var(--brand-opal)',
            2: 'var(--brand-sandy)',
            3: 'var(--brand-capri)',
            4: 'var(--brand-green)'
          }
        }
      },
      bars: {
        margin: 'var(--margin-large) 0 var(--margin-base)'
      },

      bar: {
        background: {
          color: {
            _: 'var(--color-gray-100)',
            active: {
              0: 'var(--color-gray-500)',
              1: 'var(--brand-opal)',
              2: 'var(--brand-sandy)',
              3: 'var(--brand-capri)',
              4: 'var(--brand-green)'
            }
          }
        },
        border: {
          radius: 'var(--border-radius-large)'
        },
        height: '4px',
        margin: '0 0 0 var(--margin-small)',
        transition: 'all .25s ease-in-out'
      }
    }
  },
  provider: {
    background: {
      color: {
        _: 'var(--color-gray-100)',
        hover: 'var(--color-gray-50)'
      }
    },
    border: {
      _: '1px solid transparent',
      color: {
        focus: 'var(--color-blue-400)',
        hover: 'var(--color-gray-400)'
      },
      radius: 'var(--border-radius-large)'
    },
    box: {
      shadow: {
        focus: '0 0 0 2px var(--color-blue-400)',
        hover: '0 0 0 3px var(--color-gray-100)'
      }
    },
    color: 'var(--color-text)',
    font: {
      size: 'var(--font-size-medium)'
    },
    height: '52px',
    image: {
      width: '22px'
    },
    margin: '0 0 0 var(--margin-base)',
    width: '100%'
  },
  accounts: {
    list: {
      border: {
        _: '1px solid var(--border-color)',
        radius: ' var(--border-radius-base)'
      },
      item: {
        border: {
          top: '1px solid var(--border-color)'
        },
        padding: 'var(--padding-large)',
        background: {
          color: {
            hover: 'var(--color-gray-100)'
          }
        },
        font: {
          weight: {
            active: 'var(--font-weight-medium)'
          }
        },
        first: {
          border: {
            radius: {
              hover: ' var(--border-radius-base) var(--border-radius-base) 0 0'
            }
          }
        },
        last: {
          border: {
            radius: {
              hover: '0 0 var(--border-radius-base) var(--border-radius-base)'
            }
          }
        },
        email: {
          font: {
            size: 'var(--font-size-medium)'
          }
        },
        timestamp: {
          color: 'var(--color-gray-600)'
        },
        trash: {
          color: {
            _: 'var(--color-gray-600)',
            active: 'var(--color-gray-900)'
          }
        },
        check: {
          background: {
            color: 'var(--color-white)'
          },
          border: '1px solid var(--border-color)',
          color: 'var(--color-green-400)'
        }
      }
    }
  }
};
