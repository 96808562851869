<script>
  import { format } from 'date-fns';
  import { LocalStorage } from 'Common/utils/local-storage';

  export let email = '';
  export let accountsCache = [];
  export let selectedAccount = {};
  export let useDifferentAccount = true;
  export let isValidEmail = true;
  export let onClick = () => {};

  const onRemove = (account) => {
    const storedAccounts = LocalStorage.get('accounts');

    if (storedAccounts[account.email]) {
      LocalStorage.remove('accounts', account.email);
    }

    const updatedStoredAccounts = LocalStorage.get('accounts');

    accountsCache = Object.keys(updatedStoredAccounts).map((key) => updatedStoredAccounts[key]);

    if (!accountsCache.length) {
      selectedAccount = null;
      email = '';
      useDifferentAccount = true;
    } else {
      selectedAccount = accountsCache[0];
    }
  };

  const onAccountSelect = (e, account) => {
    selectedAccount = account;
    email = selectedAccount.email;
    isValidEmail = true;
    useDifferentAccount = false;

    onClick(e);
  };
</script>

<div class="account-picker">
  <ul>
    {#each accountsCache as account}
      <li tabindex="0" class={account.email === selectedAccount.email && !useDifferentAccount ? 'active' : ''} on:click={(e) => onAccountSelect(e, account)}>
        <div class="option">
          <div>{account.email}</div>
          <small>Last login: {format(new Date(account.lastUsed), 'MMM dd, yyyy h:mm aa')}</small>
        </div>
        <i class="os-icon-check" />
        <i class="os-icon-trash-2" tabindex="0" on:click={() => onRemove(account)} />
      </li>
    {/each}
  </ul>
</div>

<style lang="scss" src="./accounts.scss"></style>
