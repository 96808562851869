<script>
  import { format } from 'svelte-i18n';

  import { Input } from 'Client/components/input';

  export let ref = null;
  export let value = '';
  export let placeholder = $format('label.PASSWORD_PLACEHOLDER');

  let icon = 'os-icon-eye-off';
  let type = 'password'
  let visible = false;

  const toggle = () => visible = !visible;

  $: icon = visible ? 'os-icon-eye' : 'os-icon-eye-off';
  $: type = visible ? 'text' : 'password';
</script>

<div class="password-preview">
  <Input
    {type}
    bind:ref={ref}
    bind:value={value}
    on:input
    on:change
    on:focus
    on:blur
    on:keyup
    on:keypress
    on:keydown
    aria-hidden={!visible}
    {placeholder}
    {...$$props} />
  <button type="button" on:click={toggle} aria-label={$format('label.TOGGLE_PASSWORD_VISIBILITY')}>
    <i class="{icon}" />
  </button>
</div>

<style lang="scss" src="./preview.scss"></style>
