import { addMessages, init, locale } from 'svelte-i18n';
import { LocalStorage } from 'Common/utils/local-storage';

import en from './locales/en.json';
import es from './locales/es.json';

const savedLocale = LocalStorage.get('locale');

if (!savedLocale) {
  LocalStorage.create('locale', { lang: 'en' });
}

// TODO: Future Kieran
// Switch to register() once code-splitting changes are merged
addMessages('en', en);
addMessages('es', es);

init({
  fallbackLocale: 'en',
  initialLocale: savedLocale?.lang ?? 'en'
});

locale.subscribe((lang) => LocalStorage.update('locale', { lang }));
