<script>
  import { format } from 'svelte-i18n';

  import { Page } from 'Client/components/page';
  import { Panel } from 'Client/components/panel';
</script>

<Page id="not-found">
  <Panel>
    <h1>{$format('label.OOPS')}</h1>
    <p>{@html $format('label.MESSAGE_WE_CANT_FIND_THE_PAGE')}</p>
    <br />
    <p>{@html $format('label.RETURN_TO_SIGN_IN')}</p>
  </Panel>
</Page>

<style lang="scss" src="./not-found.scss"></style>
