import { APITransport } from 'Client/transports/api';

export const AccountService = {
  create(username, password, token) {
    return APITransport.post({
      path: '/auth/register',
      body: {
        username,
        password,
        token
      }
    });
  },
  verify(resetToken, username, password) {
    return APITransport.post({
      path: '/auth/verify_token',
      body: {
        resetToken,
        username,
        password
      }
    });
  }
};
