<script>
  import { format } from 'svelte-i18n';

  export let password = null;
  export let passwordRules = null;
  export let policy = null;
  export let strength = 0;

  let rules = null;

  const messages = {
    uppercase: $format('label.PASSWORD_POLICY_UPPERCASE'),
    lowercase: $format('label.PASSWORD_POLICY_LOWERCASE'),
    digits: $format('label.PASSWORD_POLICY_DIGITS'),
    specialchars: $format('label.PASSWORD_POLICY_SPECIALCHARS'),
    minlength: $format('label.PASSWORD_POLICY_MINLENGTH')
  };

  const groups = {
    // N minimum uppercase
    uppercase: '(?=(.*?[A-Z]){{value},})',
    // N minimum lowercase
    lowercase: '(?=(.*?[a-z]){{value},})',
    // N minimum digits
    digits: '(?=(.*?[\\d]){{value},})',
    // N minimum special characters
    specialchars: '(?=(.*?[@$!%*#?&]){{value},})',
    // N min password length
    minlength: '.{{value},}'
  };

  let items = [];

  $: {
    if (policy) {
      items = [];

      rules = policy.auth?.providers?.password?.rules;

      if (rules) {
        let expression = '^';

        Object.keys(rules).forEach((rule) => {
          if (groups[rule]) {
            const value = rules[rule];
            const pattern = groups[rule].replace('{value}', value);
            const message = messages[rule].replace('{value}', value);

            expression += pattern;

            items.push({
              rule,
              message: value === 1 ? message.replace('s.', '.') : message,
              regexp: `^${pattern}.+$`
            })
          }
        });

        items.push({
          rule: 'strength',
          message: $format('label.PASSWORD_STRENGTH_QUALITY')
        })

        if (!rules?.minlength) {
          expression += '.+';
        }

        expression += '$';
        passwordRules = new RegExp(expression, 'g');
      }
    }
  }
</script>

{#if rules}
  <div class="password-policy">
    <small class="margin-large--top">
      <b>{$format('label.MESSAGE_YOUR_COMPANY_PASSWORD_POLICY')}</b>
      <ul class="password-policy--rules">
        {#each items as item}
          <li>
            <i class={`os-icon-${item.rule === 'strength' && strength > 1 || item.rule !== 'strength' && !!password.match(item.regexp) ? 'check' : 'circle'}`} />
            <span>{@html item.message}</span>
          </li>
        {/each}
      </ul>
    </small>
  </div>
{/if}

<style lang="scss" src="./policy.scss"></style>
