'use strict';

// Implements traditional debounce on any async operation by wrapping the function
// and hiding the internal implementation from the consumer.
// consumer gets back a (cancelable) promise that magically resolves whenever the debounce
// timer elapses
const debouncePromise = (fn, wait) => {
  const debouncedFn = (...args) => {
    debouncedFn.callCount = (debouncedFn.callCount || 0) + 1;

    if (!debouncedFn.future) {
      debouncedFn.future = {};
      debouncedFn.future.promise = new Promise((resolve, reject) => {
        debouncedFn.future.resolve = resolve;
        debouncedFn.future.reject = reject;
      });
    }

    const currentCall = debouncedFn.callCount;

    if (debouncedFn.timeout) {
      clearTimeout(debouncedFn.timeout);
      delete debouncedFn.timeout;
    }

    debouncedFn.timeout = setTimeout(async () => {
      delete debouncedFn.timeout;
      const { resolve, reject } = debouncedFn.future;
      try {
        const res = await fn(...args);

        // it's possible that another call was made while we were waiting for previous one, in that case, just ignore the result of anything other than the latest one
        if (debouncedFn.callCount === currentCall) {
          resolve(res);
          delete debouncedFn.future;
        }
      } catch (err) {
        // it's possible that another call was made while we were waiting for previous one, in that case, just ignore the result of anything other than the latest one
        if (debouncedFn.callCount === currentCall) {
          reject(err);
          delete debouncedFn.future;
        }
      }
    }, wait);

    return CancelablePromise(debouncedFn.future.promise, debouncedFn.cancel);
  };

  debouncedFn.cancel = () => {
    if (debouncedFn.timeout) {
      clearTimeout(debouncedFn.timeout);
      delete debouncedFn.timeout;
    }

    if (debouncedFn.future) {
      // Bump the call count, so that if there is a pending promise, we ignore the result, since we're going to reject it below
      debouncedFn.callCount++;
      debouncedFn.future.reject(new Error('Canceled'));
      delete debouncedFn.future;
    }
  };

  return debouncedFn;
};

// Implements polling loop for async operations without exposing the caller
// to the internal implementation of the loop & just representing the result
// as a (cancelable) promise that magically becomes complete when the polling
// succeeds or fails
//
// Parameters
//
// pollFn - function - invoked in a loop - use to make the initial request (if needed), and poll for completion
//   arguments:
//     result - result from resolution of previous call to pollFn (undefined for first iteration)
//     counter - iteration count - 0 based
//   return value: promise - rejection aborts loop
//
// isCompleteFn - function - invoked after every resolve of a pollFn promise - use to check if poll should continue
//   arguments:
//     result - result from resolution of current call to pollFn
//     counter - iteration count - 0 based
//   return value: boolean - true - done polling, false - keep going
//
// onComplete - optional - function - invoked once when isCompleteFn returns true
//   arguments:
//     result - result from resolution of current call to pollFn
//
// interval - milliseconds - wait time between calls to pollFn
const poll = ({ pollFn, isCompleteFn, onComplete, interval }) => {
  let counter = 0;
  let isCanceled = false;
  const future = {};
  const promise = new Promise((resolve, reject) => {
    future.resolve = resolve;
    future.reject = reject;
  });

  const { resolve, reject } = future;

  let pollTimeout;
  let onCompletePromise;

  const pollAgain = async (params = {}) => {
    pollTimeout = null;

    if (isCanceled) {
      return;
    }

    try {
      const pollRes = await pollFn(params, counter);
      if (!isCompleteFn(pollRes, counter)) {
        counter++;
        pollTimeout = setTimeout(() => pollAgain(pollRes), interval);
      } else {
        if (onComplete) {
          onCompletePromise = onComplete(pollRes);
          const res = await onCompletePromise;
          resolve(res);
        } else {
          resolve(pollRes);
        }
      }
    } catch (err) {
      reject(err);
    }
  };

  pollAgain();

  return CancelablePromise(promise, () => {
    isCanceled = true;

    if (pollTimeout) {
      clearTimeout(pollTimeout);
    }

    if (onCompletePromise && onCompletePromise.cancel) {
      onCompletePromise.cancel();
    }

    reject(new Error('Canceled'));
  });
};

// Wrapper for Promise that adds .cancel() method & proliferates it through the promise chain
// i.e. promise.then(...).catch(...).finally(...).cancel() will still call the cancel method
//      on the original promise
// Arguments:
//  promise: promise object to wrap & extend with cancel method
//  cancel: method that performs the operations necessary to cancel the underlying operation
//          that promise is awaiting completion of
const CancelablePromise = (promise, cancel) => ({
  cancel,

  then(...args) {
    return CancelablePromise(promise.then(...args), cancel);
  },

  catch(...args) {
    return CancelablePromise(promise.catch(...args), cancel);
  },

  finally(...args) {
    return CancelablePromise(promise.finally(...args), cancel);
  }
});

// Wrapper for Promise.all that adds a cancel method which calls cancel() on all promises in the array
CancelablePromise.all = (promises) =>
  CancelablePromise(Promise.all(promises), () => {
    promises.forEach((promise) => {
      if (promise && promise.cancel) {
        promise.cancel();
      }
    });
  });

module.exports = {
  debouncePromise,
  poll,
  CancelablePromise
};
