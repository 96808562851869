import { APITransport } from 'Client/transports/api';

export const PasswordService = {
  reset(username) {
    return APITransport.post({
      path: '/auth/reset_password',
      body: {
        username
      }
    });
  },
  update(oldPassword, newPassword, token) {
    return APITransport.post({
      path: '/auth/update_password',
      body: {
        password1: oldPassword,
        password2: newPassword,
        token
      }
    });
  },
  verify(username, password) {
    return APITransport.post({
      path: '/auth/login',
      body: {
        username,
        password
      }
    });
  },
  verify2FA(username, password, code) {
    return APITransport.post({
      path: '/auth/login',
      body: {
        username,
        password: `${password}||${code}`
      }
    });
  }
};
