<script>
  import { ThemeWrapper } from 'svelte-themer';
  import { Router, Route } from 'svelte-navigator';

  import { base, themes } from './themes';
  import { setupViewport } from './setup';

  import { CreatePassword } from 'Client/pages/password';
  import { Forgot } from 'Client/pages/forgot';
  import { Home } from 'Client/pages/home';
  import { NotAllowed } from 'Client/pages/errors/not-allowed';
  import { NotFound } from 'Client/pages/errors/not-found';
  import { Register } from 'Client/pages/register';

  import { Header } from 'Client/components/header';
  import { Footer } from 'Client/components/footer';

  import { appStore } from 'Client/stores/app';
  import { getBootstrap } from 'Client/app/bootstrap';

  setupViewport();

  const config = getBootstrap();
  const url = window.location.pathname !== '' ? window.location.pathname : '/';
  const prefix = null;

  appStore.set(config);
</script>

<ThemeWrapper {base} {themes} {prefix}>
  <Router {url} primary={false}>
    <Header />
    <div class="layout">
      <div class="left-pane">
        <img src="https://opensense.s3.amazonaws.com/logo-horizontal-white.svg" alt="" />
      </div>
      <div class="right-pane">
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/forgot">
          <Forgot />
        </Route>
        <Route path="/password">
          <CreatePassword />
        </Route>
        <Route path="/not-allowed">
          <NotAllowed />
        </Route>
        <Route path="/">
          <Home />
        </Route>
        <Route path="/*">
          <NotFound />
        </Route>
      </div>
    </div>
    <Footer />
  </Router>
</ThemeWrapper>

<!-- 
  <svelte:head>
    <script src="https://www.google.com/recaptcha/api.js?render=6LeGsooaAAAAADfO9ashQNigbF8GmKdRAYyVtUbn"></script>
  </svelte:head> 
-->
<style lang="scss" src="./app.scss"></style>
