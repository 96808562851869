<script>
  import { onMount } from 'svelte';
  import { format } from 'svelte-i18n';

  import { Panel } from 'Client/components/panel';
  import { Pill } from 'Client/components/pill';
  import { Provider } from 'Client/components/provider';

  import { queryParams } from 'Common/utils/query-params';

  export let showPassword = false;
  export let reset = null;
  export let email = null;
  export let providers = [];

  const onClick = () => (showPassword = true);

  onMount(() => {
    queryParams.set({
      step: 'choose',
      email
    });
  });
</script>

<Panel className={providers.length ? 'subtleFadeInUp' : 'subtleFadeOutDown'}>
  <div class="choose-authentication">
    <div class="email">
      <Pill onRemove={reset}>{email}</Pill>
    </div>

    <hr />

    <p class="message">
      {$format('label.MESSAGE_CHOOSE_AUTHENTICATION')}
    </p>

    <div class="options">
      {#each providers as provider}
        {#if provider.type === 'password'}
          <Provider icon="os-icon-lock" label={$format('label.PASSWORD')} on:click={onClick} />
        {:else}
          <Provider url={provider.redirectUrl} icon={provider.imageUrl} label={provider.name} />
        {/if}
      {/each}
    </div>
  </div>
</Panel>

<style lang="scss" src="./choose.scss"></style>
