import Axios, { CancelToken } from 'axios';

import { TransportUtils } from './utils';
import { CancelablePromise } from 'Common/utils/promise';

const API = 'https://login.sendergen.com';

const axios = TransportUtils.axiosCreate({
  baseURL: API
});

const generateRequest =
  (method) =>
  ({ baseURL = API, path, body, query, headers, timeout }) => {
    const source = CancelToken.source();

    const request = axios
      .request({
        baseURL,
        method,
        url: path,
        data: body,
        params: query,
        headers: {
          ...headers
        },
        timeout,
        cancelToken: source.token
      })
      .then(
        ({ data }) => data || true,
        (thrown) => {
          if (!Axios.isCancel(thrown)) {
            const { response } = thrown;

            if (response.status === 401) {
              return;
            }

            return response?.data;
          }
        }
      );

    return CancelablePromise(request, () => source.cancel('Canceled'));
  };

export const APITransport = {
  get: generateRequest('get'),
  post: generateRequest('post'),
  put: generateRequest('put'),
  delete: generateRequest('delete')
};
