<script>
  export let className = '';
  export let ref = null;
</script>

<div class="panel {className}" bind:this={ref} {...$$props}>
  <slot />
</div>

<style lang="scss" src="./panel.scss"></style>
