<script>
  export let icon = null;
  export let label = null;
  export let url = null;
</script>

{#if url !== null}
  <a href={url} {...$$props}>
    {#if icon.includes('os-icon-')}
      <i class={icon} />
    {:else}
      <img src={icon} alt={label} />
    {/if}
    <span>{label}</span>
  </a>
{:else}
  <button on:click {...$$props}>
    {#if icon.includes('os-icon-')}
      <i class={icon} />
    {:else}
      <img src={icon} alt={label} />
    {/if}
    <span>{label}</span>
  </button>
{/if}

<style lang="scss" src="./provider.scss"></style>
