<script>
  export let value = '';
  export let ref = null;
  export let disabled = null;
  export let validation = null;
  export let valid = validation === null;

  const regexp = new RegExp(validation);

  $: valid = validation ? regexp.test(value) : true;
</script>

<input
  bind:this={ref}
  aria-disabled={disabled}
  on:input
  on:change
  on:focus
  on:blur
  on:keyup
  on:keydown
  on:keypress
  bind:value
  {disabled}
  {valid}
  {...$$props} />

<style lang="scss" src="./input.scss"></style>
