<script>
  import { format } from 'svelte-i18n';
  import { useNavigate } from 'svelte-navigator';

  import { Button } from 'Client/components/button';

  const navigate = useNavigate();
</script>

<img src="https://opensense.s3.amazonaws.com/send.svg" alt={$format('label.OPENSENSE_LOGO')} />

<h1>{$format('label.SUCCESS')}</h1>

<p>{$format('label.MESSAGE_RESET_PASSWORD_INSTRUCTIONS')}</p>

<div class="actions">
  <Button kind="link" on:click={() => navigate('/')}>
    {$format('label.BACK')}
  </Button>
</div>

<style lang="scss" src="./instructions.scss"></style>
