<script>
  import { onMount } from 'svelte';
  import { format } from 'svelte-i18n';

  import { Button } from 'Client/components/button';
  import { Loader } from 'Client/components/loader';
  import { Logo } from 'Client/components/logo';
  import { Panel } from 'Client/components/panel';
  import { Page } from 'Client/components/page';
  import { PasswordPreview } from 'Client/components/password/preview';
  import { PasswordStrength } from 'Client/components/password/strength';
  import { PasswordPolicy } from 'Client/components/password/policy';

  import { AccountService } from 'Client/services/account';
  import { PolicyService } from 'Client/services/policy';

  const url = new URL(location.href);
  const params = new URLSearchParams(url.search);
  const email = params.get('email');
  const token = params.get('token');

  let loading = null;
  let password = '';
  let passwordCreationError = '';
  let passwordRef = null;
  let passwordRequirements = null;
  let passwordScore = 0;
  let policy = null;

  const onSubmit = async (e) => {
    e.preventDefault();

    loading = true;

    const response = await AccountService.verify(token, email, password);

    loading = false;

    if (response === undefined || response?.code === 422) {
      passwordCreationError = $format('label.ERROR_VERIFYING_TOKEN');
    } else {
      if (response?.portalurl) {
        window.location.replace(response.portalurl);
      }
    }
  };

  const getPolicy = async () => {
    if (email) {
      policy = null;
      policy = await PolicyService.get(email);
    }

    setTimeout(() => passwordRef && passwordRef.focus(), 0);
  };

  const onKeyUp = () => {
    passwordCreationError = '';
  };

  onMount(getPolicy);

  $: canSubmit = passwordScore > 1 && (policy && passwordRequirements ? !!password.match(passwordRequirements) : true);
</script>

<Page id="create-password">
  <Logo />

  <Panel>
    <form on:submit={onSubmit}>
      <Loader show={loading} backdrop={true} />

      <h1>{$format('label.CREATE_PASSWORD')}</h1>

      <p>{$format('label.MESSAGE_CREATE_PASSWORD')}</p>

      <PasswordPreview
        bind:ref={passwordRef}
        id="new-password"
        name="new-password"
        autocomplete="new-password"
        on:keyup={onKeyUp}
        bind:value={password}
        class="margin-large--top"
        placeholder={$format('label.PASSWORD_PLACEHOLDER')}
        aria-label={$format('label.PASSWORD_PLACEHOLDER')}
      />

      <PasswordStrength {password} bind:score={passwordScore} />

      <PasswordPolicy {policy} {password} strength={passwordScore} bind:passwordRules={passwordRequirements} />

      {#if passwordCreationError}
        <div class="error">{passwordCreationError}</div>
      {/if}

      <div class="actions">
        <Button kind="primary" disabled={!canSubmit} type="submit">
          {$format('label.SAVE')}
        </Button>
      </div>
    </form>
  </Panel>
</Page>

<style lang="scss" src="./create.scss"></style>
