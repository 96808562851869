<script>
  import { onMount } from 'svelte';
  import { format } from 'svelte-i18n';

  import { Button } from 'Client/components/button';
  import { Input } from 'Client/components/input';
  import { Loader } from 'Client/components/loader';
  import { Panel } from 'Client/components/panel';

  import { PasswordService } from 'Client/services/password';

  import { debounce } from 'Common/utils/debounce';
  import { queryParams } from 'Common/utils/query-params';

  export let show2FA = false;
  export let email = null;
  export let password;

  const pattern = '^[0-9]$';

  let loading = false;
  let isValidCode = false;
  let verificationErrorMessage = '';

  let digits = new Array(6).fill().map(() => ({ value: '', isValid: false }));

  const onKeyDown = debounce((e) => {
    const nextElementSibling = e.target.nextElementSibling;
    verificationErrorMessage = '';

    if (e.keyCode >= 48 && e.keyCode <= 57 && nextElementSibling?.tagName === 'INPUT') {
      nextElementSibling.focus();
      nextElementSibling.select();
    }
  }, 10);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isValidCode) {
      loading = true;

      const code = digits.map(({ value }) => value).join('');
      const response = await PasswordService.verify2FA(email, password, code);

      if (response === undefined || response?.message === 'AUTH_FAILURE') {
        verificationErrorMessage = $format('label.ERROR_VERIFICATION_CODE_NOT_MATCH');
        loading = false;
      } else {
        if (response?.portalurl) {
          window.location.replace(response.portalurl);
        }
      }
    }
  };

  const onRequestNewCode = async () => {
    const response = await PasswordService.verify(email, password);

    if (response !== undefined) {
      verificationErrorMessage = '';
      digits = digits.map(() => ({ value: '', isValid: false }));
    }
  };

  const onClick = () => (show2FA = false);

  onMount(() => {
    queryParams.set({
      step: '2fa',
      email
    });

    setTimeout(() => digits[0].elementRef.focus(), 0);
  });

  $: isValidCode = digits.map(({ isValid }) => isValid).every(Boolean);
</script>

<Panel id="two-factor-auth" className={verificationErrorMessage ? 'shake' : ''}>
  <form on:submit={onSubmit}>
    <Loader show={loading} backdrop={true} />

    <h1>{$format('label.TWO_FACTOR_AUTH')}</h1>

    <p>{$format('label.MESSAGE_VERIFICATION_CODE_EMAIL')}</p>

    <div class="code">
      {#each digits as digit}
        <Input type="text" bind:ref={digit.elementRef} placeholder="0" {pattern} bind:value={digit.value} bind:valid={digit.isValid} maxlength="1" on:keydown={onKeyDown} />
      {/each}
    </div>

    {#if verificationErrorMessage}
      <div class="error">{verificationErrorMessage}</div>
    {/if}

    <div class="resend">
      <Button kind="link" on:click={onRequestNewCode}>
        {$format('label.REQUEST_NEW_CODE')}
      </Button>
    </div>

    <div class="actions">
      <Button kind="link" on:click={onClick}>
        {$format('label.CANCEL')}
      </Button>
      <Button kind="primary" type="submit" disabled={!isValidCode}>
        {$format('label.CONTINUE')}
      </Button>
    </div>
  </form>
</Panel>

<style lang="scss" src="./code.scss"></style>
