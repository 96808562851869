const queryParams = {
  delete(...args) {
    const params = new URLSearchParams(location.search);

    args.forEach((key) => params.delete(key));

    window.history.pushState({}, '', `${location.pathname}?${params}`);
  },
  get(key){
    const params = new URLSearchParams(location.search);

    return params.get(key);
  },
  set(name, value) {
    const params = new URLSearchParams(location.search);

    if (typeof(name) === 'object') {
      Object.keys(name).forEach((key) => params.set(key, name[key]));
    } else {
      params.set(name, value);
    }

    window.history.pushState({}, '', `${location.pathname}?${params}`);
  }
};

module.exports = {
  queryParams
};
