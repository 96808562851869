<script>
  export let className = '';
</script>

<div class="page {className}" {...$$props}>
  <div class="page-content">
    <slot />
  </div>
</div>

<style lang="scss" src="./page.scss"></style>
