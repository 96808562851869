<script>
  import { format } from 'svelte-i18n';

  const year = new Date().getFullYear();
</script>

<footer>
  <div class="footer-wrapper">
    <div class="copyright">
      <span>{$format('label.COPYRIGHT', { values: { year } })} &middot;</span>
      <span>{$format('label.OPENSENSE_INC')}</span>
    </div>
    <div class="links">
      <a href="https://www.opensense.com/terms-and-conditions" target="_blank">{$format('label.TERMS')}</a>
      <a href="https://www.opensense.com/privacy-policy" target="_blank">{$format('label.PRIVACY')}</a>
      <a href="https://www.opensense.com/contact" target="_blank">{$format('label.CONTACT_US')}</a>
    </div>
  </div>
</footer>

<style lang="scss" src="./footer.scss"></style>
